import { get } from "lodash";
import { date } from "yup";
import { InternalUser } from "../types/app";

export type SmartyResponse = {
  suggestions: SmartyAddress[];
};

export type SmartyAddress = {
  city: string;
  entries: number;
  formattedStreet_line: string;
  secondary: string;
  state: string;
  street_line: string;
  zipcode: string;
};

export type CompanyNote = {
  id: number;
  contactId: number;
  text: string;
};

export type Company = {
  name: string;
  companyTypeId: number | null;
  id: number;
  dynamicsGuid: string;
  branches: CompanyBranch[];
  notes: Note[];
  isNewCompanyEntry: boolean;
  website: string;
  tempLookupCodesFromImport: string;
  contactRoles: ContactRole[];
  companyType: CompanyType;
  email: string;
  emailPolicyTo: string;
  phone: string;
  phoneCountryCode: string;
  fax: string;
  faxCountryCode: string;
  isActive: boolean;
  lookupCode: string;
  primaryContactId?: number;
};

export type Contact = {
  id: number;
  lookupCode: string;
  salutation: string;
  suffix: string;
  firstName: string;
  middleName: string;
  lastName: string;
  preferredName: string;
  personalEmail: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  professionId: number | null;
  reportsToId: number;
  dynamicsGuid: string;
  phoneNumbers: Phone[];
  notes: Note[];
  profession: Role | null;
  birthday?: Date;
  isDynamicsContact: boolean;
  isActive: boolean;
};

export type DynamicsContact = {
  id: string;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  emailAddress: string;
  phone: string;
  mobilePhone: string;
  companyName: string;
  lookupCode: string;
};

export type Note = {
  id: number;
  contactId: number;
  text: string;
  createdAt: string;
  updatedAt: string;
  authorName: string;
  authorUsername: string;
  isClientInstructions: boolean;
};

export type Role = {
  id: number;
  name: string;
};

export type ContactRole = {
  id: number;
  contactId: number;
  companyBranchId: number;
  roleId: number | null;
  businessEmail: string | null;
  phoneNumbers: Phone[];
  branch: CompanyBranch | null;
  role: Role | null;
};

export type Phone = {
  number: string;
  extension: string;
  countryCode: string;
  type: string;
  key: string;
};

export type CompanyBranch = {
  id: number;
  companyId: number;
  isHeadquarters: boolean;
  email: string;
  phone: string;
  phoneCountryCode: string;
  countryCode: string;
  phoneExtension: string;
  fax: string;
  faxCountryCode: string;
  faxExtension: string;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  country: string | null;
  website: string;
  company: Company;
  isNewCompanyEntry: boolean;
  isNewBranchWithoutCompany: boolean;
  isNewBranchEntry: boolean;
  isReplacementBranch: boolean;
};

export type ContactRep = {
  id: number;
  contactId: number;
  internalUserId: number | null;
  contactRepTypeId: number;
  internalUser: InternalUser | null;
  key: string;
};

export type ContactSearchResults = {
  count: number;
  data: FullContact[];
};

export type CompanySearchResults = {
  count: number;
  data: Company[];
};

export interface FullContact extends Contact {
  contactRoles: ContactRole[];
  contactReps: ContactRep[];
}

export interface ContactAndCompanies {
  contact: FullContact;
  companies: Company[];
}

export type Rep = {
  fullName: string | null;
  displayName: string | null;
  name: string | null;
  description: string | null;
  id: string | null;
};

export type SearchData = {
  pageSize: number;
  pageNumber: number;
  searchTerm: string;
  roleId: number | null;
  companyTypeId: number | null;
  isContactOnlySearch?: boolean;
  isContactCompanyOnlySearch: boolean;
  activeStatus: ActiveStatusFilter;
};

export enum ActiveStatusFilter {
  ActiveOnly,
  InactiveOnly,
  All
}

export type History = {
  id: number;
  eventType: string;
  date: Date;
  username: string;
  changes: HistoryChange[];
  contact: Contact;
  company: Company;
};

export type ContactHistory = History & {
  contactId: number;
};

export type HistoryWithRedFlags = {
  id: number;
  date: Date;
  type: string;
  contact: FullContact;
  company: Company;
  eventType: string;
  username: string;
  changes: HistoryChange[];
  redFlags: String[];
};

export type RedFlags = {
  id: number;
  name: string;
  type: string;
  details: RedFlagDetail[];
};

export type RedFlagDetail = {
  username: string;
  eventType: string;
  description: string;
  date: Date;
};

export type CompanyHistory = History & {
  companyId: number;
};

export enum HistoryType {
  CONTACT = "contact",
  COMPANY = "company"
}

export type HistoryChange = {
  oldValue: string;
  newValue: string;
  fieldName: string;
  friendlyFieldName: string;
};

export type ContactWithSuspectedMatch = {
  createdDate: Date;
  name: string;
  id: number;
  suspectedMatches: SuspectedMatch[];
};

export type SuspectedMatch = {
  contact: FullContact;
  matchedOnName: boolean;
  matchedOnBusinessEmail: boolean;
  matchedOnPhoneNumber: boolean;
};

export type Location = {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
};

export type MemberData = {
  contactId: number;
  branchId: number;
  email: string;
  isPrimaryContact: boolean;
  isContactActive: boolean;
  location: Location;
  name: string;
  phones: Phone[];
  role: string;
};

export type EditCompanyData = {
  company: EditCompanyDataCompany;
  branchesToAdd: EditCompanyDataBranchesToAdd[];
  branchesToEdit: EditCompanyDataBranchesToEdit[];
  branchesToDelete: number[];
};

export type EditCompanyDataCompany = {
  id: number;
  name: string;
  website: string;
  companyTypeId: number;
  dynamicsGuid: string;
  primaryContactId: number | null;
  tempLookupCodesFromImport: string;
  phone: string | null;
  phoneCountryCode: string | null;
  phoneExtension: string | null;
  fax: string | null;
  faxCountryCode: string | null;
  email: string;
  emailPolicyTo: string;
  lookupCode: string;
};

export type EditCompanyDataBranchesToAdd = {
  isReplacementBranch: boolean;
  companyId: number | null;
  isHeadquarters: boolean;
  email: string | null;
  phone: string | null;
  phoneCountryCode: string | null;
  phoneExtension: string | null;
  fax: string | null;
  faxCountryCode: string | null;
  faxExtension: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  country: string | null;
  tempLookupCodesFromImport: string | null;
  website: string;
};

export type EditCompanyDataBranchesToEdit = {
  id: number;
  isReplacementBranch: boolean;
  companyId: number | null;
  isHeadquarters: boolean;
  email: string | null;
  phone: string | null;
  phoneCountryCode: string | null;
  phoneExtension: string | null;
  extension: string | null;
  fax: string | null;
  faxCountryCode: string | null;
  faxExtension: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  country: string | null;
  tempLookupCodesFromImport: string | null;
  website: string;
};

export type CompanyType = {
  id: number;
  name: string;
};
