import {useState, useEffect} from 'react';
import {Stack, Button, Typography, Dialog, DialogTitle, DialogContent, TextField, Grid, CircularProgress} from "@mui/material";
import {useSearchParams} from "react-router-dom";
import axios from 'axios';
import {fCurrency} from "../utils/formatNumber";
import {useOrderInfo} from "../shared/orderInfo/OrderInfoContext";

type RecordingLedgerInfo = {
  recordingFees: number | null;
  transferTax: number | null;
  mortgageTax: number | null;
  withholdingTax: number | null;
};


const ClosingChecklistLedgers = () => {
  const [searchParams] = useSearchParams();
  const orderNumber = searchParams.get("order");
  const orderInfo = useOrderInfo();
  
  const [buyerRecordingFees, setBuyerRecordingFees] = useState('');
  const [sellerRecordingFees, setSellerRecordingFees] = useState('');
  const [buyerTransferTax, setBuyerTransferTax] = useState('');
  const [sellerTransferTax, setSellerTransferTax] = useState('');
  const [mortgageTax, setMortgageTax] = useState('');
  const [withholdingTax, setWithholdingTax] = useState('');
  const [recordingModal, setRecordingModal] = useState(false);
  
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [recordingLedgerInfo, setRecordingLedgerInfo] = useState<RecordingLedgerInfo | null>(null);
  
  const [isRecordingTransferInProgress, setIsRecordingTransferInProgress] = useState(false);

  const loadExistingRecordingLedger = async () => {
    const {data} = await axios.get(`/proxy/api/fundstransfer/GetRecordingTransactions?fileNumber=${orderNumber}`);
    setIsLoading(false);
    setRecordingLedgerInfo(data);
  }
  
  useEffect(() => {
    loadExistingRecordingLedger();
  }, []);

  if (isLoading) {
    return <></>
  }

  const getRecordingLedgerUI = () => {
    if (!recordingLedgerInfo) {
      return <Button variant="outlined" onClick={() => setRecordingModal(true)}>
        Transfer Recording Fees
      </Button>
    }

    const {recordingFees, transferTax, mortgageTax, withholdingTax} = recordingLedgerInfo;
    const total = [recordingFees, transferTax, mortgageTax].filter(f => Boolean(f)).reduce((prev, curr) => prev! + curr!, 0);

    return (
        <>
          <Typography variant='subtitle1'>{fCurrency(total!)} transferred to {orderNumber}-Recording</Typography>
          {recordingFees &&
              <Typography variant='subtitle2' color='#637381'>{fCurrency(recordingFees)} Recording Fees</Typography>}
          {transferTax &&
              <Typography variant='subtitle2' color='#637381'>{fCurrency(transferTax)} Transfer Tax</Typography>}
          {mortgageTax &&
              <Typography variant='subtitle2' color='#637381'>{fCurrency(mortgageTax)} Mortgage Tax</Typography>}
          {withholdingTax &&
              <Typography variant='subtitle2' color='#637381'>{fCurrency(withholdingTax)} Withholding Tax</Typography>}
        </>
    )
  }
  
  const onRecordingModalTransferClick = async () => {
    setIsRecordingTransferInProgress(true);
    await axios.post('/proxy/api/fundstransfer/TransferToRecordingLedger', {
      fileNumber: orderNumber,
      buyerRecordingFees,
      sellerRecordingFees,
      buyerTransferTax,
      sellerTransferTax,
      mortgageTax,
      withholdingTax
    });
    await loadExistingRecordingLedger();
    setIsRecordingTransferInProgress(true);
    setRecordingModal(false);
  }

  const onRecordingModalCancelClick = () => {
    setRecordingModal(false);
  } 
  
  const shouldShowWithholdingTax = orderInfo?.properties?.some(p => ['NJ', 'MD', 'DE'].some(s => p.state === s));
  
  return (
      <>
        <Stack direction="column" spacing={1} marginRight={40} marginLeft={10} maxWidth={400}>
          {getRecordingLedgerUI()}
        </Stack>
        <Dialog open={recordingModal} maxWidth="sm" fullWidth>
          <DialogTitle>Transfer Funds to Recording</DialogTitle>
          <DialogContent sx={{ paddingBottom: 4}}>
            <Grid container spacing={2} sx={{marginTop: 1}}>
              <Grid item xs={6}>
                <TextField
                    fullWidth
                    label="Buyer Recording Fees"
                    type="number"
                    value={buyerRecordingFees}
                    onChange={(e) => setBuyerRecordingFees(e.target.value)}
                    InputProps={{
                      startAdornment: '$',
                    }}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                    fullWidth
                    label="Seller Recording Fees"
                    type="number"
                    value={sellerRecordingFees}
                    onChange={(e) => setSellerRecordingFees(e.target.value)}
                    InputProps={{
                      startAdornment: '$',
                    }}
                    InputLabelProps={{ shrink: true }} 
                    variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                    fullWidth
                    label="Buyer Transfer Tax"
                    type="number"
                    value={buyerTransferTax}
                    onChange={(e) => setBuyerTransferTax(e.target.value)}
                    InputProps={{
                      startAdornment: '$',
                    }}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                    fullWidth
                    label="Seller Transfer Tax"
                    type="number"
                    value={sellerTransferTax}
                    onChange={(e) => setSellerTransferTax(e.target.value)}
                    InputProps={{
                      startAdornment: '$',
                    }}
                    InputLabelProps={{ shrink: true }} 
                    variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                    fullWidth
                    label="Mortgage Tax"
                    type="number"
                    value={mortgageTax}
                    onChange={(e) => setMortgageTax(e.target.value)}
                    InputProps={{
                      startAdornment: '$',
                    }}
                    InputLabelProps={{ shrink: true }} 
                    variant="outlined"
                />
              </Grid>
              {shouldShowWithholdingTax && 
              <Grid item xs={6}>
                <TextField
                    fullWidth
                    label="Withholding Tax"
                    type="number"
                    value={withholdingTax}
                    onChange={(e) => setWithholdingTax(e.target.value)}
                    InputProps={{
                      startAdornment: '$',
                    }}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                />
              </Grid>
              }
            </Grid>
            
            <Grid container justifyContent="flex-end" spacing={2} sx={{ mt: 3, paddingRight: 2 }}>
              <Grid item>
                <Button onClick={onRecordingModalCancelClick} color="error" variant="contained">
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={onRecordingModalTransferClick} color="primary" variant="contained">
                  {isRecordingTransferInProgress ? <CircularProgress size={24} color="inherit" /> : 'Transfer'}
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

      </>
  )
}

export default ClosingChecklistLedgers;